import React, { useEffect } from "react"
import Header from "../../components/header"
import { AppInfoProps, appInfoModels } from "../../models/models"
import { AppInfoDetails } from "../../components/appInfo"
import Footer from "../../components/footer"
import { navigate } from "gatsby"

const App: React.FunctionComponent<any> = props => {
  const tag = props["*"]
  const model: AppInfoProps | undefined = appInfoModels.find(x => x.tag === tag)

  useEffect(() => {
    if (!model) navigate("/404")
  })

  return (
    <>
      <Header page={"Home"} />
      <div className="w-full flex items-center justify-center">
        {model && <AppInfoDetails {...model} />}
      </div>
      <Footer />
    </>
  )
}

export default App
